import { SET_COMMUNITY_ID, SET_COMMUNITY } from './actionTypes'

const INTIAL_STATE = {}

const communityReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMMUNITY_ID:
      return {
        ...state,
        communityId: action.payload,
      }
    case SET_COMMUNITY:
      return {
        ...state,
        community: action.payload,
      }
    default:
      return state
  }
}

export default communityReducer
