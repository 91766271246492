import React from 'react'

const ListItem = props => (
<li className='list-item'>
    <span className="list-item__label">{props.label}</span>
    <span className="list-item__content">{props.content}</span>
</li>
)

export default ListItem
