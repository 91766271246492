import { SET_COMMUNITY_ID, SET_COMMUNITY } from './actionTypes'

export const setCommunityId = communityId => ({
  type: SET_COMMUNITY_ID,
  payload: communityId,
})

export const setCommunity = community => ({
  type: SET_COMMUNITY,
  payload: community,
})



