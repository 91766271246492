import React from 'react'
import NeighborCreateForm from '../components/NeighborCreateForm/NeighborCreateForm'
import Box from '../ui/Box/Box'

const NeighborCreate = () => (
  <div className="container-item">
    <div className="container-item-content">
      <Box>
        <NeighborCreateForm />
      </Box>
    </div>
  </div>
)

export default NeighborCreate
