import { client } from './api-client'

/* query-endpoint connection */

function readList(communityId) {
  return client(`/properties/?community=${communityId}`)
}

function readItem(propertyId, communityId) {
  return client(`/properties/${propertyId}/?community=${communityId}`)
}

export { readList, readItem }
