import React from 'react'
import { connect } from 'react-redux'
import { setAccessToken } from '../../../infrastructure/redux/userReducer/actions'
import Auth from '../../components/Auth/Auth'
import Logo from '../../ui/Logo/Logo'

const AuthLayout = props => (
  <div className="auth-layout">
    <div className="auth-container">
      <div className="auth-container--sizer">
        <div className="login-logo-container">
          {/* <h1 className="title-container__title">admminity</h1> */}
          <Logo logoVersion={'mini'} />
          <span className="login-logo-container__hidden-divider">----</span>
          <Logo logoVersion={'complete'} />
        </div>
        <br />
        <br />
        <br />
        <Auth {...props} />
      </div>
    </div>
  </div>
)

export default connect(null, { setAccessToken })(AuthLayout)
