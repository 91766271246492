import { SET_ACCESS_TOKEN } from './actionTypes'

const INTIAL_STATE = {}

const userReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      }
    default:
      return state
  }
}

export default userReducer
