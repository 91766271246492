import React from 'react'

const Button = ({
  className = '',
  iconRight,
  iconLeft,
  circled,
  children,
  ...props
}) => {
  return (
    <button
      className={`button ${circled && 'btn-circled'} ${className}`}
      {...props}
    >
      <span className='btn__icon'>{iconLeft}</span>
      {children}
      <span className='btn__icon'>{iconRight}</span>
    </button>
  )
}

export default Button
