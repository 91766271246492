import React, { useState } from 'react'
import { login, getToken } from '../../../infrastructure/client/auth-client'
import Button from '../../ui/Button/Button'
import Input from '../../ui/Input/Input'
import Box from '../../ui/Box/Box'
import TopicTitle from '../../ui/TopicTitle/TopicTitle'
import Alert from '../../ui/Alert/Alert'

const Auth = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState()
  const [emailError, setEmailError] = useState()
  const [passwordError, setPasswordError] = useState()

  const handleSubmit = async event => {
    event.preventDefault()
    const isEmailValid = email 
    const isPasswordValid = password

    if (isEmailValid || isPasswordValid) {
      isEmailValid && setEmailError('')
      isPasswordValid && setPasswordError('') 
    } 

    if (!isEmailValid || !isPasswordValid) {
      !isEmailValid && setEmailError('email can not be empty')
      !isPasswordValid && setPasswordError('password can not be empty')
      return 
    } 

    const response = await login(email, password)
    if (response.status === 401) {
      setFormError('Invalid username or password')
        return 
      }

    const token = getToken()
    props.setAccessToken(token)
  }

  const handleOnChange = event => {
    const { value, name } = event.target
    name === 'email' && setEmail(value)
    name === 'password' && setPassword(value)
  }

  return (
    <Box>
      <TopicTitle>Log in to admminity</TopicTitle>
      <br />
      <form id="submitForm" onSubmit={handleSubmit}>
        <Input
          label="Email"
          onChange={handleOnChange}
          placeholder="Email"
          type="email"
          value={email}
          name="email"
          autoFocus
          required
          error={emailError && (<h1>{emailError}</h1>)}
        />
        <br />
        <br />
        <Input
          label="Password"
          onChange={handleOnChange}
          placeholder="Password"
          type="password"
          value={password}
          name="password"
          required
          error={passwordError && (<h1>{passwordError}</h1>)}
        />
        <br />
        <br />
        <br />
        <div className="auth-button-wrapper">
          <Button
            type="submit"
            className="is-full-width"
          >
            Sign in
          </Button>
        </div>
      </form>
      {formError && (
        <>
          <br />
          <br />
          <Alert icon type="critical">
            {formError}
          </Alert>
        </>
      )}
    </Box>
  )
}

export default Auth
