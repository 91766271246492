import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { logout } from '../infrastructure/client/auth-client'

import AdminLayout from './layouts/AdminLayout/AdminLayout'
import CommunityLayout from './layouts/CommunityLayout/CommunityLayout'
// import ErrorBoundary from 'react-error-boundary'

class AuthenticatedApp extends React.Component {
  signOut = () => {
    this.props.history.push('/')
    logout()
  }

  render() {
    return (
      <>
        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
        <Switch>
          <Route exact path="/" component={AdminLayout} />
          <Route exact path="/signout" render={() => this.signOut() }/>
          <Route path="/condo/:communityId" component={CommunityLayout} />
        </Switch>
        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
      </>
    )
  }
}

export default withRouter(AuthenticatedApp)
