import React, { useEffect } from 'react'
import PropertiesList from '../components/PropertiesList/PropertiesList'
import Box from '../ui/Box/Box'

const Properties = ({ setActiveSection }) => {
  useEffect(() => {
    setActiveSection('Properties')
  })
 return (<div className="container-item">
    <div className="container-item-content">
      <Box>
        <PropertiesList />
      </Box>
    </div>
  </div>
)
}

export default Properties
