import * as neighborsClient from './neighbors-client.js'

/* queries from front to server */

async function useNeighborsList(communityId) {
  async function readNeighborsList() {
    const neighbors = await neighborsClient.readList(communityId)
    return neighbors
  }
  return readNeighborsList()
}

async function useNeighbor(neighborId, communityId) {
  async function readNeighbor() {
    const neighbor = await neighborsClient.readItem(neighborId, communityId)
    return neighbor
  }
  return await readNeighbor()
}

async function useCreateNeighbor(neighborId, communityId, neighborData) {
  async function createNeighbor() {
    const response = await neighborsClient.create(
      neighborId,
      communityId,
      neighborData,
    )
    return response
  }
  return await createNeighbor()
}

async function useUpdateNeighbor(neighborId, communityId, neighborData) {
  async function updateNeighbor() {
    const response = await neighborsClient.update(
      neighborId,
      communityId,
      neighborData,
    )
    return response
  }
  return await updateNeighbor()
}

async function useRemoveNeighbor(neighborId, communityId) {
  async function removeNeighbor() {
    const response = await neighborsClient.remove(neighborId, communityId)
    return response
  }
  return await removeNeighbor()
}

export {
  useNeighborsList,
  useNeighbor,
  useCreateNeighbor,
  useUpdateNeighbor,
  useRemoveNeighbor,
}
