import React from 'react'
import logoMini from '../../assets/admminity-a-logo-50px.png'
import logoComplete from '../../assets/admminity-logo-blue-200px.png'

const Logo = props => {
  if (props.logoVersion === 'mini') {
    return <img className="logo-component" src={logoMini} alt="logo" />
  }
  if (props.logoVersion === 'complete') {
    return <img className="logo-component--complete" src={logoComplete} alt="logo" />
  }
}

export default Logo
