import React from 'react'
import Loading from "@kiwicom/orbit-components/lib/Loading"

const Spinner = () => {
  return <Loading
  dataTest="test"
  loading
  text="Please wait, content of the page is loading..."
  type="pageLoader"
/>
}

export default Spinner
