import React from 'react'
import { withRouter } from 'react-router-dom'
import NeigborForm from '../NeighborForm/NeighborForm'
import { useCreateNeighbor } from '../../../infrastructure/client/neighbors'
import TopicTitle from '../../ui/TopicTitle/TopicTitle'

class NeighborCreateForm extends React.Component {
  state = {
    prefix: '',
    firstName: '',
    lastName: '',
    iban: '',
    dni: '',
    notes: '',
  }

  handleOnChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleOnSubmit = async event => {
    const { history } = this.props
    const { communityId } = this.props.match.params
    const { prefix, firstName, lastName, iban, dni, notes } = this.state

    event.preventDefault()

    const neighborData = {
      prefix: prefix,
      first_name: firstName,
      last_name: lastName,
      iban: iban,
      dni: dni,
      notes: notes,
      community: communityId,
    }
    await useCreateNeighbor(neighborData)

    // this.setState({
    //   prefix: '',
    //   firstName: '',
    //   lastName: '',
    //   iban: '',
    //   dni: '',
    //   notes: '',
    // })

    history.push(`/condo/${communityId}/neighbors`)
  }

  render() {
    const { handleOnChange, handleOnSubmit } = this
    return (
      <div>
        <TopicTitle>Add new neighbor</TopicTitle>
        <NeigborForm
          handleOnSubmit={handleOnSubmit}
          dataObject={this.state}
          handleOnChange={handleOnChange}
          buttonMessage={'Create Neighbor'}
          isEditable
        />
      </div>
    )
  }
}

// const mapStateToProps = state => ({
//   communityId: state.community.communityId,
// })
// export default connect(mapStateToProps)(NeighborCreateForm)

export default withRouter(NeighborCreateForm)
