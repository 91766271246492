import * as propertiesClient from './properties-client.js'

/* queries from front to server */

async function readPropertiesList(communityId) {
    return await propertiesClient.readList(communityId)
  }
  
  async function usePropertiesList(communityId) {
    const properties = readPropertiesList(communityId)
    return await properties
  }
  
  async function readProperty(propertyId, communityId) {
    const community = await propertiesClient.readItem(propertyId, communityId)
    return community
  }
  
  async function useProperty(propertyId, communityId) {
    return await readProperty(propertyId, communityId)
  }
  
  export { usePropertiesList, useProperty }