import { combineReducers } from 'redux'

import userReducer from './userReducer/userReducer'
import communityReducer from './communityReducer/communityReducer'
import layoutReducer from './layoutReducer/layoutReducer'

export default combineReducers({
    user: userReducer,
    community: communityReducer,
    layout: layoutReducer,
})