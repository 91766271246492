import React, { useEffect } from 'react'
import Box from '../ui/Box/Box'
import TopicTitle from '../ui/TopicTitle/TopicTitle'

const Dashboard = ({setActiveSection}) => {
  useEffect(() => {setActiveSection('Dashboard')})
  return(
    <div className="container-item">
      <div className="container-item-content">
        <Box>
        <TopicTitle>Dashboard</TopicTitle>
        </Box>
        <br/>
        <Box/>
        <br/>
        <Box/>
        <br/>
        <Box/>
        <br/>
        <Box/>
        <br/>
        <Box/>
      </div>
    </div>
  )}

export default Dashboard
