import React from 'react'

const NavLink = ({to, icon, children,isActive, onClick}) => {

  const isActiveClassName = isActive ? '--active' : ''
  const iconClassName= `nav-link__icon${isActiveClassName}`
  const textClassName= `nav-link__text${isActiveClassName} desktop`

return (
  <a href={to} onClick={onClick} type={!to? 'button' : 'a'} className="nav-link">
    <span to={to} className={iconClassName}>{icon}</span>
    <span to={to} className={textClassName}>{children}</span>
  </a>

)}

export default NavLink