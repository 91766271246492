import { createStore, applyMiddleware } from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './rootReducer'

const middlewares = []

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))

//activar logger 
// import logger from 'redux-logger'
// const middlewares = [logger]  
