import React, { useEffect } from 'react'
import Box from '../ui/Box/Box'
import TopicTitle from '../ui/TopicTitle/TopicTitle'

const Accountancy = ({ setActiveSection }) => {
  useEffect(() => {
    setActiveSection('Accountancy')
  })
  return (
    <div className="container-item">
      <div className="container-item-content">
      <Box>
        <TopicTitle>Accountancy</TopicTitle>
        </Box>
        <br/>
        <Box/>
        <br/>
        <Box/>
        <br/>
        <Box/>
        <br/>
        <Box/>
        <br/>
        <Box/>
      </div>
    </div>
  )
}

export default Accountancy
