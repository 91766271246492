import React from 'react'

const Box = props => {
return(
    <div className="content-box">
        {props.children}
    </div>
)
}

export default Box