import React from 'react'
import Button from '../../ui/Button/Button'
import ListInput from '../../ui/ListInput/ListInput'
import { sentenceCase } from 'change-case'
import { ReactComponent as RightIcon } from '../../assets/icons/check-symbol.svg'
import { ReactComponent as WrongIcon } from '../../assets/icons/close-button.svg'

const NeighborForm = ({
  handleOnSubmit,
  dataObject,
  handleOnChange,
  buttonMessage,
  isEditable,
  buttonType,
  handleOnClickOnDiscard,
}) => {
  // function to create form data dinamically
  const dataToFormInput = () => {
    const arr = []
    for (let key in dataObject) {
      if (key !== 'id') {
        arr.push(
          <li className="list-label" key={dataObject['id'] + key}>
            <label htmlFor={dataObject['id'] + key}>{sentenceCase(key)}</label>
            <ListInput
              id={dataObject['id'] + key}
              onChange={handleOnChange}
              placeholder={sentenceCase(key)}
              name={key}
              value={dataObject[key]}
              disabled={isEditable ? false : true}
            />
          </li>,
        )
      }
    }
    return arr
  }

  return (
    <form onSubmit={handleOnSubmit}>
      {buttonType === 'save' && (
        <div className="form__upper-buttons-container">
          <Button onClick={handleOnClickOnDiscard} circled>
            <WrongIcon title="wrong" width="inherit" height="inherit" />
          </Button>
          <Button type="submit" circled>
            <RightIcon title="right" width="inherit" height="inherit" />
          </Button>
        </div>
      )}
      <ul>{dataToFormInput()}</ul>
      {buttonMessage && buttonType !== 'save' && (
        <div className="form-button-container">
          <Button type="submit">{buttonMessage}</Button>
        </div>
      )}
    </form>
  )
}

export default NeighborForm
