import React from 'react'
import NeighborDataForm from '../components/NeighborDataForm/NeighborDataForm'
import Box from '../ui/Box/Box'

const Neighbor = () => (
  <div className="container-item">
    <div className="container-item-content">
      <Box>
        <NeighborDataForm />
      </Box>
    </div>
  </div>
)

export default Neighbor
