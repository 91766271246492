import React from 'react'
import { connect } from 'react-redux'
import { setActiveSection } from '../../../infrastructure/redux/layoutReducer/actions'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Neighbors from '../../views/Neighbors'
import Properties from '../../views/Properties'
import Info from '../../views/Info'
import Accountancy from '../../views/Accountancy'
import NeighborCreate from '../../views/NeighborCreate'
import Neighbor from '../../views/Neighbor'
import Property from '../../views/Property'
import Dashboard from '../../views/Dashboard'
import Calendar from '../../views/Calendar'
// import NavMenu from '../../components/NavMenu/NavMenu'
import Header from '../../components/Header/Header'
import Title from '../../ui/Title/Title'

const CommunityLayout = ({ community, setActiveSection }) => {
  const { path } = useRouteMatch()

  return (
    <div className="admin-layout">
      {/* <NavMenu direction={'vertical'}/> */}
      <Header navMenuOn={true} />
      <div className="main">
        <div className="main__header">
          {community ? (
            <>
              <div className="title-background">
                <Title className="title-1 text-color-special">
                  {community.name}
                </Title>
              </div>
              <div className="header-line text-color-special"></div>
            </>
          ) : null}
        </div>
        <div className="main__content">
          <div className="main__content--sizer">
            <Switch>
              <Route
                exact
                path={`${path}`}
                render={() => <Dashboard setActiveSection={setActiveSection} />}
              />
              <Route
                exact
                path={`${path}/info`}
                render={() => <Info setActiveSection={setActiveSection} />}
              />
              <Route
                exact
                path={`${path}/neighbors`}
                render={() => <Neighbors setActiveSection={setActiveSection} />}
              />
              <Route
                exact
                path={`${path}/properties`}
                render={() => (
                  <Properties setActiveSection={setActiveSection} />
                )}
              />
              <Route
                exact
                path={`${path}/accountancy`}
                render={() => (
                  <Accountancy setActiveSection={setActiveSection} />
                )}
              />
              <Route
                exact
                path={`${path}/calendar`}
                render={() => <Calendar setActiveSection={setActiveSection} />}
              />
              <Route
                exact
                path={`${path}/neighbors/createneighbor`}
                component={NeighborCreate}
              />
              <Route
                path={`${path}/neighbors/:neighborId`}
                component={Neighbor}
              />
              <Route
                path={`${path}/properties/:propertyId`}
                component={Property}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  community: state.community.community,
})

export default connect(mapStateToProps, { setActiveSection })(CommunityLayout)
