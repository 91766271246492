// import {queryCache} from 'react-query'
// const localStorageKey = '__admminity_token__'  //__bookshelf_token__
import {logout} from './auth-client'
const sessionStorageKey = 'admminityAccessToken'

/* api-client general config common to any api calls. */

function client(endpoint, { body, ...customConfig } = {}) {
  const token = window.sessionStorage.getItem(sessionStorageKey)
  const headers = { 'content-type': 'application/json' }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }

  return window
    .fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, config)
    .then(async r => {
      if (r.status === 401) {
        // if call from inside the UnAuthenticatedApp
        if (endpoint === '/auth/token/') {
          return r
        }
        // if call from inside the AuthenticatedApp
        logout()
        // refresh the page for them  when logout  
        window.location.assign(window.location)
        alert('your session has expired, please sign in again')
        return
      }
      if (r.statusText !== 'No Content') {
        //ie delete calls
        const data = await r.json()
        if (r.ok) {
          return data
        } else {
          return Promise.reject(data)
        }
      }
    })
}


export { client, sessionStorageKey }
