import React from 'react'
import { withRouter } from 'react-router-dom'
import { useProperty } from '../../../infrastructure/client/properties'
import ListItem from '../../ui/ListItem/ListItem'
import Spinner from '../../ui/Spinner/Spinner'
import { setStateAfterSpinnerTimeOut } from '../../../infrastructure/utilities/utilities'
import { sentenceCase } from 'change-case'

class PropertyDataList extends React.Component {
  state = {
    property: {},
    isLoading: true,
  }

  async componentDidMount() {
    const { propertyId, communityId } = this.props.match.params

    const propertyData = await useProperty(propertyId, communityId)
    setStateAfterSpinnerTimeOut(() =>
      this.setState({
        property: propertyData,
        isLoading: false,
      }),
    )
  }

  render() {
    const { property, isLoading } = this.state
    return isLoading ? (
      <Spinner />
    ) : (
      <ul>
        {Object.entries(property).map(
          propertyDetailField =>
            propertyDetailField[0] !== 'id' && (
              <ListItem
                label={sentenceCase(propertyDetailField[0])}
                content={propertyDetailField[1]}
                key={property.id + propertyDetailField[0]}
              />
            ),
        )}
      </ul>
    )
  }
}

export default withRouter(PropertyDataList)
