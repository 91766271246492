import React from 'react'

const ListItemButton = (props) => {
  return (
  <li>
    <button className={`list-item-button`} {...props}/>
  </li>
)
}
export default ListItemButton
