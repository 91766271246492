import React from 'react'
import { withRouter } from 'react-router-dom'
import { usePropertiesList } from '../../../infrastructure/client/properties'
import ListItemButton from '../../ui/ListItemButton/ListItemButton'
import TopicTitle from '../../ui/TopicTitle/TopicTitle'
import Spinner from '../../ui/Spinner/Spinner'
import { setStateAfterSpinnerTimeOut } from '../../../infrastructure/utilities/utilities'

class Properties extends React.Component {
  state = {
    properties: [],
    isLoading: true,
  }

  handleOnClickOnProperty = propertyId => {
    const { match, history } = this.props
    history.push(`${match.url}/${propertyId}`)
  }

  async componentDidMount() {
    const { communityId } = this.props.match.params
    const properties = await usePropertiesList(communityId)
    setStateAfterSpinnerTimeOut(
      () =>
        this.setState({
          ...this.state,
          properties: properties,
          isLoading: false,
        })
    )
  }

  render() {
    const { properties, isLoading } = this.state
    return isLoading ? (
      <Spinner />
    ) : (
      <>
        <TopicTitle>Properties</TopicTitle>
        <div className="properties-list">
          <ul>
            {properties.map(property => {
              return (
                <ListItemButton
                  key={property.id}
                  onClick={() => this.handleOnClickOnProperty(property.id)}
                >
                  {' '}
                  {property.label}
                </ListItemButton>
              )
            })}
          </ul>
        </div>
      </>
    )
  }
}

export default withRouter(Properties)
