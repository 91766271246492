import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAccessToken } from '../infrastructure/redux/userReducer/actions'
import { getToken } from '../infrastructure/client/auth-client'
import UnAuthenticatedApp from './UnAuthenticatedApp'
import AuthenticatedApp from './AuthenticatedApp'

const App = ({ setAccessToken, accessToken }) => {
  // useSessionStorageToken 
  useEffect(() =>  {
    console.log('implement useCallback in setAccestoken in login component')
    const token = getToken()
    if (token && token.length > 0) {
      setAccessToken(token)
    }
},)

  return accessToken ? <AuthenticatedApp accessToken={accessToken} />: <UnAuthenticatedApp />
}

const mapStateToProps = state => ({
  accessToken: state.user.accessToken, //globalstate.rootReducer.userReducer
})

export default connect(mapStateToProps, { setAccessToken })(withRouter(App))
