import React from 'react'
import { setStateAfterSpinnerTimeOut } from '../../../infrastructure/utilities/utilities'
import {
  useNeighbor,
  useUpdateNeighbor,
  useRemoveNeighbor,
} from '../../../infrastructure/client/neighbors'
import { withRouter } from 'react-router-dom'
import Button from '../../ui/Button/Button'
import NeighborForm from '../NeighborForm/NeighborForm'
import Spinner from '../../ui/Spinner/Spinner'
import Popover from '../../ui/Popover/Popover'
import NavLink from '../../ui/NavLink/NavLink'
import { ReactComponent as DotsIcon } from '../../assets/icons/3dots.svg'
import { ReactComponent as WrongIcon } from '../../assets/icons/close-button.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-black-24dp.svg'

class NeighborDataForm extends React.Component {
  state = {
    neighbor: {},
    isEditable: false,
    neighborId: this.props.match.params.neighborId,
    communityId: this.props.match.params.communityId,
    isLoading: true,
  }

  handleOnChange = event => {
    const { name, value } = event.target
    this.setState({
      neighbor: { ...this.state.neighbor, [name]: value },
    })
  }

  handleOnClickOnEdit = () => {
    this.setState({
      ...this.state,
      isEditable: !this.state.isEditable,
      neighborBeforeEdit: this.state.neighbor,
    })
  }

  handleOnClickOnDiscard = () => {
    this.setState({
      ...this.state,
      neighbor: this.state.neighborBeforeEdit,
      isEditable: !this.state.isEditable,
    })
  }

  handleOnSubmit = async event => {
    event.preventDefault()
    const { neighborId, communityId } = this.state
    const {
      prefix,
      first_name,
      last_name,
      iban,
      dni,
      notes,
      community,
    } = this.state.neighbor

    const neighborData = {
      prefix: prefix,
      first_name: first_name,
      last_name: last_name,
      iban: iban,
      dni: dni,
      notes: notes,
      community: community.id,
    }
    await useUpdateNeighbor(
      neighborId,
      communityId,
      neighborData,
    )
    this.handleOnClickOnEdit()
  }

  handleOnClickOnDelete = async () => {
    const { history } = this.props
    const { neighborId, communityId } = this.state
    await useRemoveNeighbor(neighborId, communityId)
    await history.push(`/condo/${communityId}/neighbors`)
  }

  async componentDidMount() {
    const { neighborId, communityId } = this.state
    const neighbor = await useNeighbor(neighborId, communityId)
    setStateAfterSpinnerTimeOut(() =>
      this.setState({
        neighbor: neighbor,
        isLoading: false,
      }),
    )
  }

  render() {
    const { handleOnChange } = this
    const { isEditable, isLoading } = this.state
    return isLoading ? (
      <Spinner />
    ) : (
      <div>
        <div className="corner-button-container">
          {!isEditable && (
            <Popover
              preferredAlign="end"
              content={
                <>
                  <NavLink
                    onClick={this.handleOnClickOnEdit}
                    icon={
                      <EditIcon title="edit" width="inherit" height="inherit" />
                    }
                  >
                    Edit neighbor
                  </NavLink>
                  <br />
                  <NavLink
                    onClick={this.handleOnClickOnDelete}
                    icon={
                      <WrongIcon
                        title="wrong"
                        width="inherit"
                        height="inherit"
                      />
                    }
                  >
                    Delete neighbor
                  </NavLink>
                </>
              }
            >
              <Button
                circled
                iconLeft={<DotsIcon title="dots" />}
              ></Button>
            </Popover>
          )}
        </div>
        {isEditable ? (
          <>
            <NeighborForm
              handleOnSubmit={this.handleOnSubmit}
              dataObject={this.state.neighbor}
              handleOnChange={handleOnChange}
              isEditable={true}
              buttonType="save"
              handleClickOnDiscard={this.handleOnClickOnDiscard}
            />
            <br />
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <NeighborForm dataObject={this.state.neighbor} />
            {/* <br />
            <div className="form-button-container">
              <Button onClick={this.handleOnClickOnEdit}>EDIT DETAILS</Button>
            </div> */}
          </>
        )}
      </div>
    )
  }
}

export default withRouter(NeighborDataForm)
