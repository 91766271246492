import React from 'react'
import PropertyDataList from '../components/PropertyDataList/PropertyDataList'
import Box from '../ui/Box/Box'

const Properties = () => (
      <Box>
        <PropertyDataList />
      </Box>
)

export default Properties
