import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { connect } from 'react-redux'
import UserMenu from '../UserMenu/UserMenu.js'
import Logo from '../../ui/Logo/Logo'
import Avatar from '../../ui/Avatar/Avatar'
import NavMenu from '../NavMenu/NavMenu'

const Header = ({ navMenuOn}) => {
  const [userMenuVisible, setUserMenu] = useState(false)

  const handleClick = () => {
    setUserMenu(!userMenuVisible)
  }

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__content--left">
          <div className="header__content__mini-logo-container">
            <Link className='logo-link' to={'/'}>
              <Logo logoVersion={'mini'} />
            </Link>
          </div>
          <div className="header__content__complete-logo-container fullhd">
            <Link className='logo-link' to={'/'}>
              <Logo logoVersion={'complete'} />
            </Link>
          </div>
        </div>
        <div className="header__content--middle">
          {navMenuOn ? (
            <NavMenu
              direction={'horizontal'}
            />
          ) : null}
          {/* {community ? (
            <Title className="title-1 text-color-special">
              {community.name}
            </Title>
          ) : null} */}
        </div>
        <div className="header__content--right">
          <Avatar onClick={handleClick}>AM</Avatar>
          <div className="user-menu-container">
            {userMenuVisible ? <UserMenu /> : null}
          </div>
        </div>
      </div>
    </header>
  )
}

// const mapStateToProps = state => ({
//   community: state.community.community,
// })

// export default connect(mapStateToProps)(Header)
export default Header
