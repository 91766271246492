import React from 'react'
import { withRouter } from 'react-router-dom'
import ListItemButton from '../../ui/ListItemButton/ListItemButton.js'
import Button from '../../ui/Button/Button.js'
import { useNeighborsList } from '../../../infrastructure/client/neighbors'
import TopicTitle from '../../ui/TopicTitle/TopicTitle.js'
import Spinner from '../../ui/Spinner/Spinner'
import { setStateAfterSpinnerTimeOut } from '../../../infrastructure/utilities/utilities'

class NeighborsList extends React.Component {
  state = {
    neighborsList: [],
    isLoading: true,
  }

  handleOnClickOnAdd = () => {
    const { match, history } = this.props
    history.push(`${match.url}/createneighbor`)
  }

  handleOnClickOnNeighbor = neighborid => {
    const { match, history } = this.props
    history.push(`${match.url}/${neighborid}`)
  }

  setNeighboursList = async () => {
    const { communityId } = this.props.match.params
    const neighborsList = await useNeighborsList(communityId)

    setStateAfterSpinnerTimeOut(
      () =>
        this.setState({
          ...this.state,
          neighborsList: neighborsList,
          isLoading: false,
        })
    )
  }

  async componentDidMount() {
    this.setNeighboursList()
  }

  render() {
    const { neighborsList, isLoading } = this.state

    return isLoading ? (
      <Spinner />
    ) : (
      <>
        <TopicTitle>Neighbors</TopicTitle>
        <div className='corner-button-container'>
        <Button onClick={this.handleOnClickOnAdd} circled>+</Button>
        </div>
        <div className="list-container">
          <ul>
            {neighborsList.map(neighbor => {
              return (
                <ListItemButton
                  key={neighbor.id}
                  onClick={() => this.handleOnClickOnNeighbor(neighbor.id)}
                >
                  {neighbor.last_name}, {neighbor.first_name}
                </ListItemButton>
              )
            })}
          </ul>
        </div>
      </>
    )
  }
}

export default withRouter(NeighborsList)
