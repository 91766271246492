import React from 'react'
import NavLink from '../../ui/NavLink/NavLink'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'


const UserMenu = () => {

  return (
    <>
      <div className="user-menu">
          <div className="user-menu__item">
            <NavLink to={'/signout'} icon={<LogoutIcon title="Logout" />}>Sign Out</NavLink>
          </div>
      </div>
    </>
  )
}

export default UserMenu
