import {client, sessionStorageKey} from './api-client'

function handleUserResponse(response) {
  const token = response.access
  if (!token) {
    return response
  }
  window.sessionStorage.setItem(sessionStorageKey, token)
  return response
}

function login(email, password) {
  return client('/auth/token/', {body: {email,password}}).then(handleUserResponse)
}

function getToken() {
  return window.sessionStorage.getItem(sessionStorageKey)
}

function logout() {
  window.sessionStorage.removeItem(sessionStorageKey)
  window.location.assign(window.location)
  //aqui habria que devolver a la root
}

function isLoggedIn() {
  return Boolean(getToken())
}

export {login, getToken, logout, isLoggedIn}

// function getUser() {
//   const token = getToken()
//   if (!token) {
//     return Promise.resolve(null)
//   }
//   return client('me').then(data => data.user)
// }

// function register({username, password}) {
//   return client('register', {body: {username, password}}).then(
//     handleUserResponse,
//   )
// }




// export {login, register, getToken, getUser, isLoggedIn}
// export {logout} from './api-client'
