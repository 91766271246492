import { SET_ACTIVE_SECTION } from './actionTypes'

const INTIAL_STATE = {}

const layoutReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_SECTION :
      return {
        ...state,
        activeSection: action.payload,
      }
    default:
      return state
  }
}

export default layoutReducer
