import { client } from './api-client'

/* query-endpoint connection */

function readList(communityId) {
  return client(`/neighbors/?community=${communityId}`)
}

function readItem(neighborId, communityId) {
  return client(`/neighbors/${neighborId}/?community=${communityId}`)
}

function create(neighborData) {
  const config = {
    body: neighborData,
  }
  return client(`/neighbors/`, config)
}

function update(neighborId, communityId, neighborData) {
  const config = {
    method: 'PUT',
    body: neighborData,
  }
  return client(`/neighbors/${neighborId}/?community=${communityId}`,config)
}

function remove(neighborId, communityId) {
  const config = {
    method: 'DELETE',
  }
    return client(`/neighbors/${neighborId}/?community=${communityId}`,config)
  }

export { readList, readItem, create , update, remove}
