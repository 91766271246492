import React, { useEffect } from 'react'
import CommunityDataList from '../components/CommunityDataList/CommunityDataList'
import Box from '../ui/Box/Box'

const Info = ({setActiveSection}) => {
  useEffect(() => {setActiveSection('Info')})
  return (
  <div className="container-item">
    <div className="container-item-content">
      <Box>
        <CommunityDataList />
      </Box>
    </div>
  </div>
)}

export default Info
