import React, { useEffect } from 'react'
import NeighborsList from '../components/NeighborsList/NeighborsList'
import Box from '../ui/Box/Box'

const Neighbors = ({ setActiveSection }) => {
  useEffect(() => {
    setActiveSection('Neighbors')
  })
  return (
    <Box>
      <NeighborsList />
    </Box>
  )
}

export default Neighbors
