import React from 'react'
import { withRouter } from 'react-router-dom'
import { useCommunity } from '../../../infrastructure/client/communities.js'
import ListItem from '../../ui/ListItem/ListItem'
import Spinner from '../../ui/Spinner/Spinner'
import TopicTitle from '../../ui/TopicTitle/TopicTitle.js'
import { setStateAfterSpinnerTimeOut } from '../../../infrastructure/utilities/utilities'
import {sentenceCase} from "change-case"

class CommunityDataList extends React.Component {
  state = {
    community: [],
    isLoading: true,
  }

  // esto hay que llevarselo a utils ya que es procesado de data
  displayCommunityData = community =>
    Object.entries(community).map((dataItem, i) => {
      if (dataItem[0] !== 'id') {
        return (
          <ListItem key={i}
          label={sentenceCase(dataItem[0])}
          content={dataItem[1]}
          />
        )
      }
      return null
    })

  async componentDidMount() {
    const { communityId } = this.props.match.params
    const community = await useCommunity(communityId)

    setStateAfterSpinnerTimeOut(
      () =>
        this.setState({
          community: community,
          isLoading: false,
        })
    )
  }

  render() {
    const { community, isLoading } = this.state
    return isLoading ? (
      <Spinner />
    ) : (
      <>
        <TopicTitle>Community info</TopicTitle>
        <ul>{this.displayCommunityData(community)}</ul>
      </>
    )
  }
}

export default withRouter(CommunityDataList)
