import React from 'react'
import CommunitiesList from '../../components/CommunitiesList/CommunitiesList'
import Header from '../../components/Header/Header'
import { setCommunity } from '../../../infrastructure/redux/communityReducer/actions'
// import Title from '../../ui/Title/Title'

class AdminLayout extends React.Component {

  render() {
    // const {community} = this.state
    return (
      <div className="admin-layout">
        <Header navMenuOn={false} />
        <div className="main">
          <div className="main__header">
          </div>
          <div className="main__content">
            <div className="main__content--sizer">
              <CommunitiesList setCommunity={setCommunity} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminLayout
