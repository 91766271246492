import { client } from './api-client'

/* query-endpoint connection */

function readList() {
  return client('/communities/')
}

function readItem(communityId) {
  return client(`/communities/${communityId}/`)
}

export { readList, readItem }

// function readItem(listItemIds = []) {
//   return client('list-items')
// }

// function readList(listItemData) {
//     return client('/communities/', {body: listItemData})
//   }

// function update(listItemId, updates) {
//   return client(`list-items/${listItemId}`, {
//     method: 'PUT',
//     body: updates,
//   })
// }

// function remove(listItemId) {
//   return client(`list-items/${listItemId}`, {method: 'DELETE'})
// }

// remove,
// update}
// export {create, read, remove, update}
