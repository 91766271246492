import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setCommunity } from '../../../infrastructure/redux/communityReducer/actions'
import ListItemButton from '../../ui/ListItemButton/ListItemButton'
import Spinner from '../../ui/Spinner/Spinner'
import { useCommunitiesList } from '../../../infrastructure/client/communities.js'
import Box from '../../ui/Box/Box'
import TopicTitle from '../../ui/TopicTitle/TopicTitle'
import { setStateAfterSpinnerTimeOut } from '../../../infrastructure/utilities/utilities'
import Input from '../../ui/Input/Input'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'

class CommunitiesList extends React.Component {
  state = {
    communities: [],
    isLoading: true,
    searchFilter: '',
  }

  handleOnclick = community => {
    this.props.history.push(`/condo/${community.id}/info`)
    this.props.setCommunity(community)
  }

  handleOnChange = event => {
    this.setState({
      searchFilter: event.target.value,
    })
  }

  displayCommunities = () => {
    let { communities, searchFilter } = this.state

    if (searchFilter) {
      const filteredCommunities = communities.filter(
        community =>
          community.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !==
          -1,
      )
      communities = filteredCommunities
    }

    return communities.length > 0
      ? communities.map(community => (
          <ListItemButton
            key={community.id}
            onClick={() => this.handleOnclick(community)}
          >
            {community.name}
          </ListItemButton>
        ))
      : null
  }

  async componentDidMount() {
    const communities = await useCommunitiesList()
    setStateAfterSpinnerTimeOut(() =>
      this.setState({
        communities: communities,
        isLoading: false,
      }),
    )
  }

  render() {
    const { isLoading } = this.state
    return isLoading ? (
      <Box>
        <Spinner />
      </Box>
    ) : (
      <>
        <Box>
          <TopicTitle>Select community </TopicTitle>
          <Input
            placeholder="Enter a community"
            prefix={<SearchIcon />}
            onChange={this.handleOnChange}
          />
          <br />
          <br />
          <br />
          <ul className="condo-list">{this.displayCommunities()}</ul>
        </Box>
      </>
    )
  }
}

export default connect(null, { setCommunity })(withRouter(CommunitiesList))
