import React from 'react'
import Title from '../Title/Title'

const TopicTitle = ({children}) => {
  return (
    <div className="topic-title-container">
      <Title className='title-1'>
        {children}
      </Title>
    </div>
  )
}

export default TopicTitle
