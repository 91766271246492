import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import { withRouter  } from 'react-router-dom'
import { ReactComponent as ChartIcon } from '../../assets/icons/analytics.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as MoneyIcon } from '../../assets/icons/money.svg'
import { ReactComponent as PeopleIcon } from '../../assets/icons/team.svg'
import { ReactComponent as BuildingIcon } from '../../assets/icons/home.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'
// import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg'
import NavLink from '../../ui/NavLink/NavLink'

const NavMenu = ({
  layoutActiveSection, direction, match: {
    params: { communityId },
  },
}) => {

  const [navActiveSection, setNavActiveSection] = useState()
  useEffect( () => {
    setNavActiveSection(layoutActiveSection)
  }, [layoutActiveSection] )

  return (
    <div className={`nav-menu--${direction}`}>
      <div className={`nav-menu--${direction}__content`}>
        <NavLink
          to={`/condo/${communityId}/`}
          icon={<ChartIcon title="Dashboard" />}
          isActive={navActiveSection === "Dashboard"}
        >Dashboard</NavLink>
        <NavLink
          to={`/condo/${communityId}/info`}
          icon={<InfoIcon title="Info" />}
          isActive={navActiveSection === "Info"}
          >Info</NavLink>
        <NavLink
          to={`/condo/${communityId}/properties`}
          icon={<BuildingIcon title="Properties" />}
          isActive={navActiveSection === "Properties"}
          >Properties</NavLink>
        <NavLink
          to={`/condo/${communityId}/neighbors`}
          icon={<PeopleIcon title="Neighbors" />}
          isActive={navActiveSection === "Neighbors"}
          >Neighbors</NavLink>
        <NavLink
          to={`/condo/${communityId}/accountancy`}
          icon={<MoneyIcon title="Accountancy" />}
          isActive={navActiveSection === "Accountancy"}
          >Accountancy</NavLink>
        <NavLink to={`/condo/${communityId}/calendar`} 
        icon={<CalendarIcon title="Calendar" />} 
        isActive={navActiveSection === "Calendar"}
        >Calendar</NavLink>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  layoutActiveSection: state.layout.activeSection
})


export default connect(mapStateToProps)(withRouter(NavMenu))
