// import {useQuery, useMutation, queryCache} from 'react-query'
// import {setQueryDataForBook} from './books'
import * as communitiesClient from './communities-client.js'

/* queries from front to server */

async function readCommunitiesList() {
  return await communitiesClient.readList().then(data => data)
}

async function useCommunitiesList() {
  const communities = readCommunitiesList()
  return await communities
}

async function readCommunity(communityId) {
  const community = await communitiesClient.readItem(communityId)
  return community
}

async function useCommunity(communityId) {
  return await readCommunity(communityId)
}

export { useCommunitiesList, useCommunity }

// function useCommunitiesList({onSuccess, ...options} = {}) {
//   const {data: communitiesList} = useQuery({
//     queryKey: 'communities-list',
//     queryFn: readCommunitiesList,
//     onSuccess: async communitiesList => {
//       await onSuccess?.(communitiesList)
//       for (const community of communitiesList) {
//         setQueryDataForBook(listItem.book)
//       }
//     },
//     ...options,
//   })
//   return listItems ?? []
// }

// function useListItem(bookId, options) {
//   const listItems = useListItems(options)
//   return listItems?.find(li => li.bookId === bookId) ?? null
// }

// function readListItems() {
//   return listItemsClient.read().then(d => d.listItems)
// }

// function useListItems({onSuccess, ...options} = {}) {
//   const {data: listItems} = useQuery({
//     queryKey: 'list-items',
//     queryFn: readListItems,
//     onSuccess: async listItems => {
//       await onSuccess?.(listItems)
//       for (const listItem of listItems) {
//         setQueryDataForBook(listItem.book)
//       }
//     },
//     ...options,
//   })
//   return listItems ?? []
// }

// const defaultMutationOptions = {
//   onError: (err, variables, recover) =>
//     typeof recover === 'function' ? recover() : null,
//   onSettled: () => queryCache.refetchQueries('list-items'),
//   useErrorBoundary: false,
//   throwOnError: true,
// }

// function onUpdateMutation(newItem) {
//   const previousItems = queryCache.getQueryData('list-items')

//   queryCache.setQueryData('list-items', old => {
//     return old.map(item => {
//       return item.id === newItem.id ? {...item, ...newItem} : item
//     })
//   })

//   return () => queryCache.setQueryData('list-items', previousItems)
// }

// function useUpdateListItem(options) {
//   return useMutation(updates => listItemsClient.update(updates.id, updates), {
//     onMutate: onUpdateMutation,
//     ...defaultMutationOptions,
//     ...options,
//   })
// }

// function useRemoveListItem(options) {
//   return useMutation(({id}) => listItemsClient.remove(id), {
//     onMutate: removedItem => {
//       const previousItems = queryCache.getQueryData('list-items')

//       queryCache.setQueryData('list-items', old => {
//         return old.filter(item => item.id !== removedItem.id)
//       })

//       return () => queryCache.setQueryData('list-items', previousItems)
//     },
//     ...defaultMutationOptions,
//     ...options,
//   })
// }

// function useCreateListItem(options) {
//   return useMutation(({bookId}) => listItemsClient.create({bookId}), {
//     ...defaultMutationOptions,
//     ...options,
//   })
// }

/*
eslint
  no-unused-expressions: "off",
*/
